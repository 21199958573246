import './plugins/axios'
import './registerServiceWorker'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import PrimeVue from 'primevue/config';


import Toast from 'primevue/toast';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';
import InputMask from 'primevue/inputmask';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Checkbox from 'primevue/checkbox';
import Menu from 'primevue/menu';
import Textarea from 'primevue/textarea';
import Message from 'primevue/message';

import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';

import 'primevue/resources/themes/fluent-light/theme.css'//'primevue/resources/themes/saga-green/theme.css'      
import 'primevue/resources/primevue.min.css'                 
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import './main.css'




import { es } from './locale';

const app = createApp(App)

//Uses
app.use(router)
app.use(store)
app.use(PrimeVue, {ripple: true,locale:es})
app.use(ToastService)
app.use(ConfirmationService)


//Componentes
app.component('Toast',Toast);
app.component('Button',Button);
app.component('Calendar',Calendar);
app.component('InputText',InputText);
app.component('Password',Password);
app.component('Card',Card);
app.component('Dropdown',Dropdown);
app.component('InputMask',InputMask);
app.component('Dialog',Dialog);
app.component('Checkbox',Checkbox);
app.component('ConfirmDialog',ConfirmDialog);
app.component('Menu',Menu);
app.component('TextArea',Textarea);
app.component('Message',Message);


//Directivas
app.directive('badge', BadgeDirective);


app.mount('#app')
