
import { defineComponent, ref, onBeforeMount, onMounted, computed ,watchEffect} from 'vue'


import ResultadosCard from '@/components/ResultadosCard.vue'
import axios from '@/plugins/axios';
import moment from 'moment';

import { useStore } from 'vuex';
import { jsPDF } from 'jspdf';


import { imprimirHC } from '@/hooks/useImpresion';


export default defineComponent({
    components:{
        ResultadosCard
    },
    setup: () => {

        const pdfjs =  new jsPDF({
                format: 'a4',
                unit:'pt',
                hotfixes:["px_scaling"]
            });



        const store = useStore();
        const contenedorResultados = ref<HTMLDivElement>();
        const resultadoFormActive = ref(false);
        
        const medicos = ref([{nombre:'Patricio Esponja'}]);
        const medicoSeleccionado = ref(null);
        const especialidades = ref([{nombre:'Pediatria'}]);
        const especialidadSeleccionada = ref(null);
        const fecha = ref([new Date()]);

        
        const evolucionesList = ref();
        const evolucionSeleccionada = ref();
        const pagina = ref(1);
        const itemsPorPagina = ref(10);
        const cantidadResultados = ref();
        
        const cantidadPaginas = computed(() => {
            return Math.ceil(cantidadResultados.value / itemsPorPagina.value);
        });

        const formatearFechas = () =>   fecha.value.length === 1 
                                        ? [...fecha.value,fecha.value[0]].map((fech:any) => moment(fech).format('YYYY-MM-DD'))
                                        : fecha.value.map((fech:any) => moment(fech).format('YYYY-MM-DD'));



        const handleVerClick = async (item:any) =>{
            try {
                
                const result = await axios.get(`/historia-clinica-get-registros/${item._id}`)
                evolucionSeleccionada.value = result.data;
                
                imprimirHC(evolucionSeleccionada.value,pdfjs, store.state.configuracionPortal.cliente.logo);
                
                pdfjs.save(`${evolucionSeleccionada.value.datos.nombre} - ${moment(evolucionSeleccionada.value.datos.fecha).format('DD_MM_YYYY')}.pdf`);
            } catch (error) {
                console.log(error);
            }
        }

        

        watchEffect(() => {
            
            if(contenedorResultados.value){
                contenedorResultados.value.removeEventListener('scroll', eventoDeScroll);
                contenedorResultados.value.addEventListener('scroll', eventoDeScroll);
            }
        })
     

        const obtenerRegistros = async () => {
            try {
                const fechas = formatearFechas();

                


                const query = encodeURIComponent(JSON.stringify({tipo:'HC','datos.isPublicable':true} || {}));
                
                
                
                
                
                const {data} = await axios.get(`/historia-clinica-get-registros/${store.state.pacienteLogueado.id}/${pagina.value}/${itemsPorPagina.value}?query=${query}`);
                
                
                evolucionesList.value = evolucionesList.value ? [...evolucionesList.value, ...data.registros] : data.registros;
                cantidadResultados.value = data.count;
                especialidades.value = data.especialidad;
                medicos.value = data.medicos;
                
            } catch (error) {
                console.log(error);
            }
        }
        
        onBeforeMount(() => {
            obtenerRegistros();
            
        })

        


        const eventoDeScroll = (e:any) => {
            const { target:{scrollTop, offsetHeight, scrollHeight} } = e;
            
            if(scrollTop + offsetHeight >= scrollHeight){
               
                pagina.value += 1;
                if(cantidadPaginas.value >= pagina.value){
                    
                    obtenerRegistros();
                }
                
                
            }
        }

        
        
        
        
        return {
            contenedorResultados,
            resultadoFormActive,
            medicos,
            medicoSeleccionado,
            especialidades,
            especialidadSeleccionada,
            fecha,
            evolucionesList,
            evolucionSeleccionada,
            handleVerClick,
            
        }
    }

})
