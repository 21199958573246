


import { defineComponent, ref, onBeforeMount} from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import useBackground from '@/hooks/useBackground';
import useLogo from '@/hooks/useLogo';
import axios from '@/plugins/axios';
import moment from 'moment';
import { AxiosError } from 'axios';

export default defineComponent({
    components:{
    },
    setup(){
        const toast = useToast();
        const store = useStore();
        const isLoad = ref(false);
        const animated = ref(false);
        const router = useRouter();
        const route = useRoute();
        const logo = useLogo();
        const BackgroundImage = useBackground()


        const tiposDNI = ref(['DNI','CI','Pasaporte'])
        const pass = ref('');
        const passRepeat = ref('');
        const user = ref('');
        const tipoDni = ref('DNI');
        
        const error = ref('');
        const ModalDisplay = ref(false);
        const ModalPassRecover = ref(false);
        const emailDeRecuperacion = ref('');
        const codigoDeRecuperacion = ref('');
        const isCodeValid = ref(false);
        
        const isModoRegistro = ref(false);
        const isModoEsperarCodigo = ref(false);
        const codigoValidacion = ref();
        const nuevoUserDocumento = ref('');
        const nuevoUserFechaNacimiento = ref('');
        const nuevoUserNombre = ref('');
        const nuevoUserApellido = ref('');
        const nuevoUserSexo = ref('');
        const nuevoUserEmail = ref('');
        const nuevoUserDireccion = ref('');
        const nuevoUserTelefono = ref('');
        const nuevoUserCarnetCobertura = ref('');
        const nuevoUserCobertura = ref();
        const nuevoUserPlan = ref();
        const nuevoUserCondicionIva = ref();
        const planesCoberturas = ref();
        const planesCondicionIva = ref();
        const coberturas = ref();
        const errorNuevoUsuario = ref('');


        //time counter
        const time = ref();
        const timer = ref();
        
        const startInterval = () => {
            time.value = moment.duration(1,'minutes');
            timer.value = setInterval(() => {
                time.value = time.value.subtract(1,'seconds');
                if(time.value.asSeconds() <= 0){
                    clearInterval(timer.value);
                }
            },1000);
        }

        const loguear = async () => {
            try{
                const body = {
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                    password:pass.value,
                }
                const result = await axios.post('pacientes/loguear-portal',body);
                if(result.data.estado){
                    return result.data
                }else{
                    throw new Error('bloqueado');
                }

            }catch(err:AxiosError | any){
                throw new Error(err);
            }
        }

        const handleClick = () => {
            isLoad.value = true;


            
             

            loguear()
            .then((datos) => {
                console.log(datos);
                animated.value = true;
                setTimeout(()=> {
                    const newLog = {
                        idusuario: store.state.configuracionPortal.cliente.usuarios[0].id,
                        idcliente: store.state.configuracionPortal.idcliente,
                        descripcion: ""
                    }
                    //Obtengo ip del usuario
                    fetch('https://api.ipify.org/?format=json')
                    .then(( response:any) => response.json())
                    .then(( response:any ) => {
                        newLog.descripcion =  `El paciente ${datos.nombre} - ${datos.numerodocumento} ingreso al sistema con la IP ${response?.ip}`
                        axios.post('gmed-logs/save', newLog)
                        .catch(err =>{
                            console.log("Error al crear log de ingreso")
                        })
                        
                    })
                    //En caso de que la Api no funcione, cargo el log sin la IP
                    .catch(() =>{
                        newLog.descripcion =  `El paciente ${datos.nombre} - ${datos.numerodocumento} ingreso al sistema`
                        axios.post('gmed-logs/save', newLog)
                        .catch(err =>{
                            console.log("Error al crear log de ingreso")
                        })
                    })


                    const { cliente } = route.params
                    store.dispatch('autenticar',datos);

                    console.log( datos.fechanacimiento.split('-')[0] === pass.value);

                    
                    datos.fechanacimiento.split('-')[0] === pass.value ?
                    router.push(`/${cliente}/cambio-pass`):
                    router.push(`/${cliente}/`)
                    
                    
                    
                    
                },500)
            })
            .catch((err) =>{
                if(err.message == 'Error: bloqueado'){
                    toast.add({severity:'error',summary:'Advertencia',detail:'No tiene permitido el ingreso al Portal',life:2000})
                }else{
                    error.value = 'Usuario y/o contraseña incorrecta'
                }
            })
            .finally(()=>{
                isLoad.value = false;
            })
        }

        const handlePassRecoverClick = async (bool:boolean) => {
            
            if(!bool){
                clearInterval(timer.value);
                ModalPassRecover.value = false;
                error.value = '';
                isCodeValid.value = false;
                codigoDeRecuperacion.value = '';
                return;
            }
            
            
            if(user.value === ''){
                error.value = 'Ingrese su numero de documento';
                const el = (document.querySelector(".p-inputtext.p-component") as HTMLElement );
                el.focus();
                (document.querySelector(".user-group") as HTMLElement ).classList.add('invalid');
                return;
            }

            isLoad.value = true;


            try {
                const { data } = await axios.post('pacientes/recuperacion-password',{
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                })


                emailDeRecuperacion.value = data.email.split('@')[0].replace(/.(?=.{4,}$)/g, '*') + '@' + data.email.split('@')[1];
                


                error.value = ''
                ModalPassRecover.value = bool;
                timer.value = null;
                startInterval();

            } catch (err:AxiosError | any) {
                error.value = `${err.response.data}`;
            }finally{
                isLoad.value = false;
            }


            
        }
        const handleChangeUser = () => {
            (document.querySelector(".user-group") as HTMLElement ).classList.remove('invalid');
        }
        
        const handleNuevoUsuarioClick = (bool:boolean) => {

            ModalDisplay.value = bool;
            if(!bool){
                limpiarCampos();
            }
        }

        const handleEnviarCodigo = async() => {
            try {
                if(codigoDeRecuperacion.value.length < 5){
                    error.value = 'Ingrese un codigo valido'
                    return;
                }


                const result = await axios.post('/pacientes/recuperacion-password-comprobacion',{
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                    codigoRecuperacion:codigoDeRecuperacion.value,
                })

                isCodeValid.value = true;
                pass.value = '';
                passRepeat.value = '';

            } catch (err:AxiosError | any) {
                error.value = `${err.response.data}`;
                ModalPassRecover.value = false;
            }
        }

        const cambioDePass = async () => {
            try {
                if(pass.value !== passRepeat.value){
                    error.value = 'Las contraseñas no coinciden'
                    return;
                }
                if(pass.value.length < 8){
                    error.value = 'La contraseña debe tener al menos 8 caracteres'
                    return;
                }
                
                
                const result = await axios.post('/pacientes/recuperacion-password-cambio',{
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                    password:pass.value,
                })

                ModalPassRecover.value = false;
                error.value = '';
                isCodeValid.value = false;
                codigoDeRecuperacion.value = '';

                toast.add({severity:'success',summary:'Exito',detail:'Se ha actualizado la contraseña correctamente.',life:1500})
                
            } catch (err:AxiosError | any) {
                error.value = `${err.response.data}`;
            }

        }


        const obtenerCoberturas = async () => {
            try {
                const {idcliente} = store.state.configuracionPortal;


                const result = await axios.get(`pacientes/obtener-coberturas/${idcliente}`);


                coberturas.value = result.data;
            } catch (err) {
                console.log(err);
            }
        }
        
        const obtenerPlanes = async (idcobertura:number) => {
            try{
                const result = await axios.get(`/pacientes/obtener-plan-cobertura/${idcobertura}`);
                planesCoberturas.value = result.data;
            }catch(error){
                console.log(error);
            }
        }

        const onCoberturaChanged = (event:any) => {
            planesCoberturas.value = [];
            planesCondicionIva.value = [];
            nuevoUserPlan.value = null;
            nuevoUserCondicionIva.value = null;
            nuevoUserCarnetCobertura.value = '';
            obtenerPlanes(event.value.id);
        }

        const onPlanesChanged = (event:any) => {
            const { v_o } = event.value; 
            

            if(v_o === 'A'){
                planesCondicionIva.value = [{label:'Gravado',value:'G'}, {label:'Exento',value:'NG'}];
            }


            if(v_o !== 'A'){
                planesCondicionIva.value = [ v_o === 'G' ?{label:'Gravado',value:'G'} : {label:'Exento',value:'NG'}];
                nuevoUserCondicionIva.value = v_o === 'G' ? {label:'Gravado',value:'G'} : {label:'Exento',value:'NG'};
            }

            

        }

        const validarCampos = async () =>{
            let valido = true;
            
            const dniInValido = await validarDNI();
            
            valido = valido && nuevoUserDocumento.value !== '';
            debugger;
            if(valido && dniInValido){
                return false;
            }

            valido = valido && nuevoUserApellido.value !== '';
            valido = valido && nuevoUserNombre.value !== '';
            valido = valido && nuevoUserEmail.value !== '';
            valido = valido && nuevoUserSexo.value !== '';
            
            valido = valido && nuevoUserFechaNacimiento.value !== '';
            valido = valido && nuevoUserCobertura.value !== null && nuevoUserCobertura.value?.nombre !== '';
            
            
            if(nuevoUserCobertura.value?.nombre?.toLowerCase() !== 'particular'){
                valido = valido && nuevoUserCarnetCobertura.value !== '';
                valido = valido && nuevoUserPlan.value !== null && nuevoUserPlan.value?.nombre !== '';
            }


            errorNuevoUsuario.value = valido ? '' : 'Complete todos los campos';

            


            return valido;
        }

        const validarDNI = async () => {
            try {
                const {idcliente } = store.state.configuracionPortal;
                const result = await axios.post('/pacientes/validar-documento',{
                    tipodocumento:tipoDni.value,
                    numerodocumento:nuevoUserDocumento.value,
                    idcliente
                })
                
                errorNuevoUsuario.value = result.data.existe ? 'El numero de documento ya existe' : '';


                return result.data.existe;

            } catch (error) {
                    console.log(error);
                    return false;
            }
        }

        const onAceptarClick = async () => {
            if(!(await validarCampos())){
                return;
            }

            try {
                codigoValidacion.value = Math.floor(Math.random() * (999999 - 100000)) + 100000;
                const {idcliente } = store.state.configuracionPortal;
                await axios.post('/pacientes/enviar-codigo-verificacion',{
                    codigo:codigoValidacion.value,
                    idcliente,
                    nombre:`${nuevoUserNombre.value} ${nuevoUserApellido.value}`,
                    mail:nuevoUserEmail.value,
                })

                

                errorNuevoUsuario.value = '';
                isModoRegistro.value = false;
                if(!isModoEsperarCodigo.value){
                    isModoEsperarCodigo.value = true;
                }
                clearInterval(timer.value);
                startInterval();
                toast.add({severity:'success',summary:'Exito',detail:'Se ha enviado el codigo correctamente.',life:1500})
                
            } catch (error) {
                console.log(error);
            }
        }

        const onAceptarCodigoClick = async () => {
            if(codigoValidacion.value.length <= 5){
                errorNuevoUsuario.value = 'Ingrese un codigo valido'
                return;
            }

            if(codigoValidacion.value !== parseInt(codigoValidacion.value)){
                errorNuevoUsuario.value = 'El codigo ingresado no es valido'
                return;
            }

            enviarSolicitudNuevoPaciente();


            
        }


        const enviarSolicitudNuevoPaciente = async () => {
            try {
                const {idcliente } = store.state.configuracionPortal;
                await axios.post('/paciente/confirmar-nueva-solicitud',
                {
                    idcliente,
                    paciente:{
                        nombre:`${nuevoUserApellido.value} ${nuevoUserNombre.value}`,
                        dni:`${tipoDni.value} - ${nuevoUserDocumento.value}`,
                        fechanacimiento:moment(nuevoUserFechaNacimiento.value).format('DD/MM/YYYY'),
                        email:nuevoUserEmail.value,
                        telefono:nuevoUserTelefono.value,
                        sexo:nuevoUserSexo.value,
                        direccion:nuevoUserDireccion.value,
                        cobertura:nuevoUserCobertura.value.nombre,
                        condicionIva:nuevoUserCondicionIva?.value?.label || '',
                        plan:nuevoUserPlan.value?.nombre || '',
                        carnet:nuevoUserCarnetCobertura?.value || '',
                    }
                })

                toast.add({severity:'success',summary:'Exito',detail:'Se ha enviado la solicitud correctamente.',life:1500})
                isModoEsperarCodigo.value = false;
                isModoRegistro.value = false;
                ModalDisplay.value = false;
                limpiarCampos();
            } catch (error) {
                console.log(error);
                toast.add({severity:'error',summary:'Error',detail:'No se pudo enviar la solicitud.',life:1500})
                clearInterval(timer.value);
                
            }
        }

        const limpiarCampos = () => {
            nuevoUserDocumento.value = '';
            nuevoUserApellido.value = '';
            nuevoUserNombre.value = '';
            nuevoUserEmail.value = '';
            nuevoUserFechaNacimiento.value = '';
            nuevoUserTelefono.value = '';
            nuevoUserDireccion.value = '';
            nuevoUserCobertura.value = null;
            nuevoUserPlan.value = null;
            nuevoUserCarnetCobertura.value = '';
            nuevoUserCondicionIva.value = null;
            nuevoUserSexo.value = '';
            planesCondicionIva.value = null;
            errorNuevoUsuario.value = '';
            codigoDeRecuperacion.value = '';
            codigoValidacion.value = '';
            isModoEsperarCodigo.value = false;
            isModoRegistro.value = false;
            clearInterval(timer.value);
        }



        onBeforeMount(() => {
            obtenerCoberturas();
        })


        return {
            animated,
            isLoad,
            BackgroundImage,
            ModalDisplay,
            time,
            ModalPassRecover,
            emailDeRecuperacion,
            codigoDeRecuperacion,
            isCodeValid,
            logo,
            pass,
            passRepeat,
            user, 
            error, 
            tipoDni, 
            tiposDNI, 
            handleClick,
            handleChangeUser,
            handlePassRecoverClick, 
            handleNuevoUsuarioClick,
            handleEnviarCodigo,
            cambioDePass,
            //NuevoUsuario
            isModoRegistro,
            isModoEsperarCodigo,
            nuevoUserDocumento,
            nuevoUserFechaNacimiento,
            nuevoUserNombre,
            nuevoUserApellido,
            nuevoUserSexo,
            nuevoUserEmail,
            nuevoUserDireccion,
            nuevoUserTelefono,
            nuevoUserCarnetCobertura,
            nuevoUserCobertura,
            nuevoUserPlan,
            nuevoUserCondicionIva,
            planesCoberturas,
            planesCondicionIva,
            coberturas,
            onCoberturaChanged,
            onPlanesChanged,
            errorNuevoUsuario,
            onAceptarClick,
            onAceptarCodigoClick,
        }
    }
});
