
import { defineComponent, ref } from 'vue'

import moment from 'moment';

export default defineComponent({
    props:['datos'],
    setup(props){
        const es = moment(props.datos.datos.fecha).locale('es')
        const fecha = ref(es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY'))
        


        return {
            fecha,
        }
    }
})
