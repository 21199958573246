import {jsPDF} from 'jspdf';
import moment from 'moment';

interface Parametros{
    [key:string]:any,
    size:{h:number,w:number},
    margin:number,
    familiaTipografica:string,
    fontSize:number
}




const dibujarCabeceraHC = (pdfjs:jsPDF,margenX:number,margenY:number,espacio:number,HC:any,  logo:string) => {
    /**--------------------------
     *      Page Size.
     * --------------------------
     *  height = 841.89   
     *  width = 595.28       
     */
    

    pdfjs.addImage(logo, margenX, 10 ,100,50);

    pdfjs.setDrawColor(0)
    pdfjs.setFillColor(255, 255, 255)

    const anchoPagina = 593;

    //Titulo
    pdfjs.setFontSize(20);
    pdfjs.setFont('helvetica','bold');
    pdfjs.text(HC.datos.nombre, (anchoPagina / 2), margenY + 15, {align:'center'} )


    pdfjs.setFontSize(12);

    pdfjs.roundedRect(margenX - 10 , margenY + 20 , anchoPagina - ((margenX - 10) * 2)  , (espacio * 4) + 10 , 3, 3, 'S')

    //Paciente.
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Paciente:', margenX, margenY + 20 +  espacio, {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(`${HC.datos.datosPaciente.nombre}`, margenX + pdfjs.getTextWidth('Paciente:  '), margenY + 20 +  espacio, {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    
    //Documento.
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Documento:', margenX, margenY + 20 +  (espacio * 2), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(`${HC.datos.datosPaciente.tipodocumento} - ${HC.datos.datosPaciente.documento}`, margenX + pdfjs.getTextWidth('Documento:  '), margenY + 20 +  (espacio * 2), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    //Edad.
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Edad:', anchoPagina - (margenX * 2) - pdfjs.getTextWidth(`${HC.datos.datosPaciente.edad}`), margenY + 20 +  (espacio * 2), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(`${HC.datos.datosPaciente.edad}`, anchoPagina - (margenX * 2) - pdfjs.getTextWidth(`${HC.datos.datosPaciente.edad}`) + pdfjs.getTextWidth(`Edad: `)  , margenY + 20 +  (espacio * 2), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    
    //Cobertura.
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Cobertura:', margenX, margenY + 20 +  (espacio * 3), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(`${HC.datos.datosPaciente.cobertura}`, margenX + pdfjs.getTextWidth('Cobertura:  '), margenY + 20 +  (espacio * 3), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    
    //Plan.
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Plan:', margenX, margenY + 20 +  (espacio * 4), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(`${HC.datos.datosPaciente.planCobertura}`, margenX + pdfjs.getTextWidth('Plan:  '), margenY + 20 +  (espacio * 4), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    //Carnet.
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Carnet:',anchoPagina - (margenX * 2) - pdfjs.getTextWidth(`${HC.datos.datosPaciente.edad}`), margenY + 20 +  (espacio * 4), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(`${HC.datos.datosPaciente.carnet}`,anchoPagina - (margenX * 2) - pdfjs.getTextWidth(`${HC.datos.datosPaciente.edad}`) + pdfjs.getTextWidth('Carnet:'), margenY + 20 +  (espacio * 4), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    
    //Fecha.
    pdfjs.setFontSize(10)
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Fecha:', margenX, margenY + 30 +  (espacio * 5), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(`${moment(HC.datos.fecha).format('DD/MM/YYYY - HH:mm')}`, margenX + pdfjs.getTextWidth('Fecha:  '), margenY + 30 +  (espacio * 5), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFontSize(12)

    //dato del Medico
    pdfjs.setFillColor(200, 200, 200);
    pdfjs.roundedRect(margenX - 10 , margenY + 40 +  (espacio * 5) , anchoPagina - ((margenX - 10) * 2)  , (espacio * 2) + 10 , 3, 3, 'F')
    
    //Efector
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Efector:', margenX, margenY + 40 +  (espacio * 6), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(HC.medico.nombre, margenX + pdfjs.getTextWidth('Efector:  '), margenY + 40 +  (espacio * 6), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    //Especialidad
    pdfjs.setFont('helvetica','bold');
    pdfjs.text('Especialidad:', margenX, margenY + 40 +  (espacio * 7), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })
    pdfjs.setFont('helvetica','normal');
    pdfjs.text(HC.especialidad.nombre, margenX + pdfjs.getTextWidth('Especialidad:   '), margenY + 40 +  (espacio * 7), {maxWidth: (anchoPagina - ((margenX - 10) * 2) / 2) })

};

export const imprimirHC = (HC:any,pdfjs:jsPDF, logo:string) => {
    /**--------------------------
     *      Page Size.
     * --------------------------
     *  height = 841.89   
     *  width = 595.28       
     */
    
     const margenX = 60;
     const margenY = 80;
    

    const dibujarFirma = () => {
        if(!HC?.medico?.pieFirma) return 0;
        const anchoPagina = (pdfjs as jsPDF & {getPageWidth: () => number}).getPageWidth();
        const altoPagina = (pdfjs as jsPDF & {getPageHeight: () => number}).getPageHeight();
        let lineasFirma = 0;              
        const altoLinea = 12;
        const altoFirma = 50;
        const anchoFirma = 100;
        const lineasPie = pdfjs.splitTextToSize(`${HC.medico.pieFirma}`, anchoPagina - (margenX * 2));
        pdfjs.setFontSize(12);
        lineasFirma = lineasPie.length * altoLinea;
        debugger;
        pdfjs.addImage(HC.medico.logofirma, (anchoPagina - (2 * margenX) ) / 2 - 25  , (altoPagina - margenY) - lineasFirma  - altoLinea,anchoFirma, altoFirma )


        //pie de firma
        lineasFirma -= altoLinea + 1;
        pdfjs.setFont('helvetica','normal')
        lineasPie.forEach((text: string) =>{
            //center text in the page
            const deltaX = ((anchoPagina - 2 * margenX ) / 2) + (anchoFirma / 2) - (pdfjs.getTextWidth(text)/ 2) - (anchoFirma / 4);
            //deltaX =  //((anchoPagina - 2 * margenX ) / 2) + (anchoFirma / 2) - (pdfjs.getTextWidth(text) / 2) - deltaX / 4 ;
            pdfjs.text(text, deltaX , (altoPagina - margenY) + altoFirma - lineasFirma , {})
            lineasFirma -= altoLinea;
        })
        
        return lineasPie.length * altoLinea + altoFirma + 2;
    }

    const maxLineOfset = 35 - (dibujarFirma() / 10)
    const minLineOfset = 9; 
    const espacio = 20;
    const fontSize = 12
    
    pdfjs.setFontSize(fontSize);

    dibujarCabeceraHC(pdfjs,margenX,margenY,espacio,HC,logo);

    //Componentes.
    let lineOffset = minLineOfset;
    HC.datos.componentes.forEach((componente:any) => {
        const { valor, label, tipo , titulo, opciones} = componente;

        if(lineOffset >= maxLineOfset){
            lineOffset = 9;
            pdfjs.addPage();
            dibujarCabeceraHC(pdfjs,margenX,margenY,espacio,HC,logo);
        }

        



        if(tipo === 'Separador'){
            pdfjs.setFont('helvetica','bold');
            pdfjs.text(`${titulo}`, (595 / 2) - (pdfjs.getTextWidth(titulo) / 2) , margenY + 40 +  (espacio * lineOffset));
            pdfjs.line((595 / 2) - (pdfjs.getTextWidth(titulo) / 2 ), margenY + 40 +  (espacio * lineOffset) + 3 , (595 / 2) - (pdfjs.getTextWidth(titulo) / 2 ) + pdfjs.getTextWidth(titulo), margenY + 40 +  (espacio * lineOffset) + 3)
            pdfjs.setFont('helvetica','normal');
            lineOffset += 2;
            return;
        }
        
        //Label.
        pdfjs.setFont('helvetica','bold');
        pdfjs.text(`${tipo === 'Formulario' ? valor.titulo : label || 'Etiqueta'}:`, margenX , margenY + 40 +  (espacio * lineOffset))
        pdfjs.setFont('helvetica','normal');
        lineOffset ++;
        
        
        // campos

        if(tipo === 'Checkbox') {
            const valores = [...valor || []].reduce((prev,curr,index:number) => {
                if(curr && opciones[index]){
                    
                    return prev + ',' + opciones[index]
                }
                return prev;
            }, '' )
            pdfjs.text(valores , margenX , margenY + 40 +  (espacio * lineOffset))
            lineOffset++
            return
        }


        
        const result =  pdfjs.splitTextToSize(tipo === 'Formulario' ? (valor.texto || '') : (valor || ''), 590 - (margenX * 2));
        result.forEach((text:string) => {
            if(lineOffset >= maxLineOfset){
                lineOffset = 9;
                pdfjs.addPage();
                dibujarCabeceraHC(pdfjs,margenX,margenY,espacio,HC,logo);
                pdfjs.text(text, margenX , margenY + 40 +  (espacio * lineOffset))
                lineOffset++
                return;
            }
            pdfjs.text(text, margenX , margenY + 40 +  (espacio * lineOffset));
            lineOffset++;
        })

        lineOffset++;
    })

};

/* 

        Recetas


*/

const dibujarCabeceraRC = (pdfjs:jsPDF, margenX:number, margenY:number, espacio:number,RC:any,logo:string) => {
            
            
    pdfjs.addImage(logo, margenX, 15, 100, 50);
    //Caja
    pdfjs.setDrawColor(0)
    pdfjs.setFillColor(255, 255, 255)

    

    //pdfjs.roundedRect(margenX - 10, margenY + 15, 419 - ((margenX - 10) * 2)  , (espacio * 6) - espacio / 2  , 3, 3, 'S')
    pdfjs.setDrawColor(150)
    pdfjs.line(margenX  , margenY,  419 - margenX, margenY)
    pdfjs.setDrawColor(0)

    //Paciente
    pdfjs.setFont('helvetica','bold')
    pdfjs.text('Paciente:',margenX, margenY + espacio * 2 , {})
    pdfjs.setFont('helvetica','normal')
    pdfjs.text(`${RC.datos.datosPaciente.nombre}`,margenX + pdfjs.getTextWidth('Paciente:  '),margenY + espacio * 2)


    //Documento
    pdfjs.setFont('helvetica','bold')
    pdfjs.text('Documento:',margenX, margenY + espacio * 3, {})
    pdfjs.setFont('helvetica','normal')
    pdfjs.text(`${RC.datos.datosPaciente.tipodocumento} - ${RC.datos.datosPaciente.numerodocumento}`,margenX + pdfjs.getTextWidth('Documento:  '), margenY + espacio * 3)

    //cobertura
    pdfjs.setFont('helvetica','bold')
    pdfjs.text('Cobertura:',margenX, margenY + espacio * 4, {})
    pdfjs.setFont('helvetica','normal')
    pdfjs.text(`${RC.datos.datosPaciente.cobertura}`,margenX + pdfjs.getTextWidth('Cobertura:  '),margenY + espacio * 4)

    //plan
    pdfjs.setFont('helvetica','bold')
    pdfjs.text('Plan:',margenX, margenY + espacio * 5, {})
    pdfjs.setFont('helvetica','normal')
    pdfjs.text(`${RC.datos.datosPaciente.planCobertura}`,margenX +pdfjs.getTextWidth('Plan:  ') ,margenY + espacio * 5)

    //carnet
    pdfjs.setFont('helvetica','bold')
    pdfjs.text('Carnet:',margenX, margenY + espacio * 6, {})
    pdfjs.setFont('helvetica','normal')
    pdfjs.text(`${RC.datos.datosPaciente.carnet}`,margenX + pdfjs.getTextWidth('Carnet:  ') ,margenY + espacio * 6)

    //fecha
    pdfjs.setFont('helvetica','bold')
    pdfjs.text('Fecha:',419 - (pdfjs.getTextWidth(moment(RC.datos.fecha).format('DD/MM/YYYY')) + pdfjs.getTextWidth('Fecha: '))  - margenX , margenY + espacio * 6 , {})
    pdfjs.setFont('helvetica','normal')
    pdfjs.text(moment(RC.datos.fecha).format('DD/MM/YYYY'), 419 - margenX - pdfjs.getTextWidth(moment(RC.datos.fecha).format('DD/MM/YYYY'))  ,margenY + espacio * 6)
}

const dibujarCuerposRC = (pdfjs:jsPDF,cuerpo:string,tipo:string,margenX:number,margenY:number,espacio:number,RC:any,firma:string,pie:string,logo:string) => {
    const anchoPagina = 419;
    const altoPagina = 595;
    const result = pdfjs.splitTextToSize(cuerpo, anchoPagina - (margenX * 2));

    if(tipo === 'Indicaciones'){
        pdfjs.addImage(logo, margenX, 15, 100, 50);
    }
    
    const dibujarFirma = () => {  
        let lineasFirma = 0;              
        const altoLinea = 10;
        const altoFirma = 50;
        const anchoFirma = 100;
        const lineasPie = pdfjs.splitTextToSize(`${RC.medico.pieFirma || ''}`, anchoPagina - (margenX * 2));
        
        lineasFirma = lineasPie.length * altoLinea;
        pdfjs.addImage(RC.medico.logofirma, (anchoPagina - (2 * margenX) ) / 2 - 25  , (altoPagina - margenY) - lineasFirma  - altoLinea,anchoFirma, altoFirma )

        //nombre.

        //pie de firma
        lineasFirma -= altoLinea + 1;
        pdfjs.setFont('helvetica','normal')
        lineasPie.forEach((text:string) =>{
            const deltaX = ((anchoPagina - 2 * margenX ) / 2) + (anchoFirma / 2) - (pdfjs.getTextWidth(text)/ 2) - (anchoFirma / 4);
            pdfjs.text(text, deltaX , (altoPagina - margenY) + altoFirma - lineasFirma , {})
            lineasFirma -= altoLinea;
        })

        return lineasPie.length * altoLinea + altoFirma + 2;
    }

    //me fijo que las lineas no sobrepasen el alto de la hoja
    let counter = 0;
    let altoFirma = 0;
    if(tipo === 'Rp./' && firma){             
            altoFirma = dibujarFirma();
    }
    
    result.forEach((text:string) => {
        
        const altura = margenY + espacio * 11 + (counter * espacio);
        
        if(altura >= ((altoPagina - margenY) - altoFirma)){
            counter = 0;
            pdfjs.addPage('a5');
            if(tipo === 'Rp./'){             
                dibujarCabeceraRC(pdfjs,margenX, margenY,espacio,RC,logo)
                dibujarFirma();
                pdfjs.setFont('helvetica','bold')
                pdfjs.text(tipo,margenX, margenY + espacio * 9, {})
                pdfjs.setFont('helvetica','normal')
                return;
            }

            pdfjs.setFont('helvetica','bold')
            pdfjs.text(tipo,margenX, margenY + (espacio * 2), {})
            pdfjs.setFont('helvetica','normal')

        }

        
        if(tipo === 'Rp./'){
            pdfjs.text(text,margenX + 5 ,margenY + espacio * 11 + (counter * espacio))
            counter ++;
            return;
        }

        pdfjs.text(text,margenX + 5 ,margenY + (espacio * 3) + (counter * espacio))
        counter ++;
        
    })
    
    



}
export const imprimirRC = (RC:any, pdfjs:jsPDF,firma:string,pie:string, logo:string) => {
    const cuerpos = (isDuplicado:boolean) =>{
        dibujarCabeceraRC(pdfjs,margenX, margenY,espacio,RC,logo)


        //diagnostico 
        pdfjs.setFont('helvetica','bold')
        pdfjs.text('Diagnostico:',margenX, margenY + espacio * 7.5, {})
        pdfjs.setFont('helvetica','normal')
        pdfjs.text(`${RC.datos.diagnostico.codigo} - ${RC.datos.diagnostico.diagnostico}`,margenX + pdfjs.getTextWidth('Diagnostico:  ') ,margenY + espacio * 7.5)
        
        
        
        //Imprimir RP
        pdfjs.setFont('helvetica','bold')
        pdfjs.text('Rp./',margenX, margenY + espacio * 9, {})
        pdfjs.setFont('helvetica','normal')
        dibujarCuerposRC(pdfjs,RC.datos.rp,'Rp./',margenX,margenY,espacio,RC,firma,pie,logo);
    }

    /**--------------------------
     *      Page Size.
     * --------------------------
     *  height = 595.28   
     *  width = 419.53      
     */
    
    
    const margenX = 30;
    const margenY = 80;
    const espacio = 15;
    const fontSize = 10
    pdfjs.setFontSize(fontSize);
    
    

    
    cuerpos(false);


    

    //Imprimir Indicaciones
    pdfjs.addPage('a5');
    pdfjs.setFont('helvetica','bold')
    pdfjs.text('Indicaciones',margenX, margenY + (espacio * 2), {})
    pdfjs.setFont('helvetica','normal')
    dibujarCuerposRC(pdfjs,RC.datos.indicaciones,'Indicaciones',margenX,margenY,espacio,RC,firma,pie,logo);

    
}


export const imprimirTurno = (turno:any,pdfjs:jsPDF,logo:string) => {
    
    const renderizar = () => {
        const parametros = {
            size: { 
                h: pdfjs.internal.pageSize.getHeight(), 
                w: pdfjs.internal.pageSize.getWidth() 
            },
            margin: 20,
            familiaTipografica:"helvetica",
            fontSize: 8,
        }
        let deltaY = 0;
        

        deltaY += dibujarLogo(parametros);
        deltaY += dibujarTitulo(deltaY, parametros);
        deltaY += dibujarDatosPaciente(deltaY, parametros);
        deltaY += dibujarEspecialidadSucursalDireccion(deltaY, parametros);
        deltaY += dibujarObservaciones(deltaY, parametros);
        deltaY += dibujarOperador(deltaY, parametros);

        pdfjs.setLineWidth(1.5)
        pdfjs.line(parametros.margin, deltaY , parametros.size.w - parametros.margin, deltaY);

        dibujarFechaHoraImpresion(parametros);

        dibujarPreparaciones(parametros);
    }

    const dibujarLogo = (parametros:Parametros):number => {
        const {margin, fontSize} = parametros;
        
        const altoImagen = 60;
        const anchoImagen = 120;
        pdfjs.addImage(logo, 'JPEG', margin, margin , anchoImagen, altoImagen);

        return altoImagen + margin + fontSize;
    }

    const dibujarTitulo = (deltaY:number, parametros:Parametros):number =>{
        const {fontSize, size, familiaTipografica} = parametros;
            const anchoTitulo = ()=> pdfjs.getTextWidth(`Reserva de Cita`);
            const fontSizeTurno = 18;

            pdfjs.setFontSize(fontSizeTurno);
            pdfjs.setFont(familiaTipografica, 'normal', 'bold');

            
            pdfjs.text(`Reserva de Cita`, (size.w / 2) - (anchoTitulo() / 2) , deltaY + fontSize);


            pdfjs.setFontSize(fontSize);
            pdfjs.setFont(familiaTipografica, 'normal', 'normal');


            return fontSizeTurno;
    }

    const dibujarDatosPaciente = (deltaY:number, parametros:Parametros) => {
        const {fontSize, margin , size , familiaTipografica } = parametros;
        const {paciente, cobertura, carnet, planCobertura} = turno;
        const anchoCaja = size.w - (margin * 2);
        let altoCaja = 0;
        let altura = fontSize + (margin/2);
        let deltaX = margin + fontSize;
        
        pdfjs.setFontSize(fontSize);
        
        //paciente 
        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Paciente:`, deltaX , deltaY + altura);
        
        deltaX += pdfjs.getTextWidth(`Paciente: `);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${paciente.nombre}`, deltaX , deltaY + altura);
        
        //cobertura
        altura += (fontSize * 2);
        deltaX = margin + fontSize;

        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Cobertura:`, deltaX , deltaY + altura);
        
        deltaX += pdfjs.getTextWidth(`Cobertura: `);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${cobertura.nombre}`, deltaX , deltaY + altura);

        //carnet
        altura = fontSize + (margin/2);
        deltaX = anchoCaja / 2;
        
        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Carnet:`, deltaX , deltaY + altura);
        
        deltaX += pdfjs.getTextWidth(`Carnet: `);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${carnet}`, deltaX , deltaY + altura);
        

        //plan
        altura += (fontSize * 2);
        deltaX = anchoCaja / 2;
        
        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Plan:`, deltaX , deltaY + altura);
        
        deltaX += pdfjs.getTextWidth(`Plan: `);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${planCobertura.nombre}`, deltaX , deltaY + altura);

        //caja
        altoCaja = altura + (margin / 2)
        pdfjs.setLineWidth(1.5);
        pdfjs.roundedRect(margin, deltaY, anchoCaja , altoCaja, 5, 5,'S');
        pdfjs.setLineWidth(1);


        return altoCaja + (margin / 2);



    }

    const dibujarEspecialidadSucursalDireccion = (deltaY:number, parametros:Parametros) =>{
        const {margin, familiaTipografica, } = parametros;
        const {medicoEspecialidad} = turno;
        const fontSize = 12;
        let altura = fontSize + (margin/2);
        let deltaX = margin + fontSize
        pdfjs.setFontSize(fontSize);

        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Especialidad:`, deltaX , deltaY + altura);

        deltaX += pdfjs.getTextWidth(`Especialidad: `);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${medicoEspecialidad?.servicioEspecialidad?.descripcion}`, deltaX , deltaY + altura);

        altura += fontSize + 3;
        deltaX = margin + fontSize;

        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Sucursal:`, deltaX , deltaY + altura);

        deltaX += pdfjs.getTextWidth(`Sucursal: `);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${medicoEspecialidad?.sucursal?.sucursal}`, deltaX , deltaY + altura);

        altura += fontSize + 3;
        deltaX = margin + fontSize;

        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Dirección:`, deltaX , deltaY + altura);

        deltaX += pdfjs.getTextWidth(`Dirección: `);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${medicoEspecialidad?.sucursal?.direccion}`, deltaX , deltaY + altura);

        
        return altura + (fontSize * 2);


    }

    const dibujarObservaciones =(deltaY:number, parametros:Parametros ) =>{
        const {margin , fontSize, size, familiaTipografica} = parametros;
        const {fecha, hora, medicoEspecialidad} = turno;
        const fechaMoment = moment(fecha, 'YYYY-MM-DD').format('DD/MM/YYYY');
        const horaMoment = moment(hora, 'HH:mm:ss').format('HH:mm');
        const deltaX = margin + fontSize;
        let altura = fontSize;
        pdfjs.setFontSize(fontSize + 2);

        pdfjs.setFont(familiaTipografica, 'normal', 'bold');
        pdfjs.text(`Observaciones:`, deltaX , deltaY + altura);

        

        altura += (fontSize + 4);
        
        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`Presentarse el ${fechaMoment} a las ${horaMoment} con ${medicoEspecialidad?.medico?.nombre}`, deltaX , deltaY + altura);


        return altura + fontSize;
        

    }

    const dibujarOperador = (deltaY:number, parametros:Parametros) => {
        const {margin , size, fontSize, familiaTipografica} = parametros;
        const {usuario} = turno;
        const altura = fontSize;
        let deltaX = size.w;


        pdfjs.setFontSize(fontSize);
        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        deltaX -= (pdfjs.getTextWidth(`Otorgado Por: ${usuario.nombre} ${usuario.apellido}`) + margin);

        pdfjs.text(`Otorgado Por: ${usuario.nombre} ${usuario.apellido}`, deltaX  , deltaY + altura);

        return altura + (fontSize * 3);

    }

    const dibujarFechaHoraImpresion = (parametros:Parametros) =>{
        const {margin, familiaTipografica , size } = parametros;
        const fecha = moment();

        pdfjs.setFontSize(8);

        pdfjs.setFont(familiaTipografica, 'normal', 'normal');
        pdfjs.text(`${fecha.format('DD/MM/YYYY')}`, size.w - (margin / 2) - pdfjs.getTextWidth(`${fecha.format('DD/MM/YYYY')}`) , margin);

        pdfjs.text(`${fecha.format('HH:mm')}`, size.w - (margin / 2) - pdfjs.getTextWidth(`${fecha.format('HH:mm')}`) - (pdfjs.getTextWidth(`${fecha.format('DD/MM/YYYY')}`) / 4 )  , margin + 8);





    }
    
    const dibujarPreparaciones = (parametros:Parametros) => {
        const { margin, size, fontSize, familiaTipografica} = parametros
        const {turnoPrestacions} = turno
        let deltaY = margin;
        let deltaX = margin;

        const nuevaPagina = () => {
            
            deltaY = dibujarLogo(parametros);

            deltaY += fontSize + 10;
        
            pdfjs.setFont(familiaTipografica, 'normal','bold');

            pdfjs.setFontSize(fontSize);

            pdfjs.text(`Preparaciones: `, margin, deltaY);

            pdfjs.setFont(familiaTipografica, 'normal','normal');

            deltaX += pdfjs.getTextWidth(`Preparaciones: `);
        }

        if(turnoPrestacions.length === 0) return;
        
        if(!turnoPrestacions.some(({prestacion}:any) => prestacion.preparacion)) return;


        pdfjs.addPage();

        


        


        nuevaPagina();

        


        turnoPrestacions.forEach(({prestacion}:any) => {
                const textos = pdfjs.splitTextToSize(prestacion.preparacion, size.w - margin - deltaX );
                
                textos.forEach((texto:string) => {
                    deltaY += fontSize;
                    if(deltaY > size.h - margin){
                        pdfjs.addPage();
                        nuevaPagina();
                        deltaY += fontSize + 10;
                    }
                    pdfjs.text(`${texto}`, deltaX, deltaY);
                })

                deltaY += fontSize;
                
            
        })




    }

    renderizar();

}